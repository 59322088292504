@import url('https://fonts.googleapis.com/css2?family=Pirata+One&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Pirata One', cursive;
} 

.root{
  margin-top: 5vh;
}

#root{
  margin: 0;
  padding: 0;
  background-image: url('./Photos/worldMap.png')
}

.App{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.border{
  border: 5px solid black;
}
.mapSpace-size {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #27282d;
  user-select: none;
  overflow: hidden;
  flex-grow: 1;
  height: 95vh;
  position: relative;
  
}

.containers-container{
  display: inline-block;
  position: relative;
  justify-content: center;
  max-width: 90%;
  max-height: 90%;
  /* flex-grow: 1; */
  
}

.container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(49, minmax(min-content, 1fr));
  grid-template-rows: repeat(49, minmax(min-content, 0.9fr));
  box-shadow: 0 0 15vh darkgray;
  /* flex-grow: 1; */
  max-width: 100%;
  max-height: 100%;
}

.audio{
  z-index: 1;
}

.map{
  display: flex;
  max-width: 100%;
  width:100%;
  max-height:85vh;
  height: auto;
  grid-area: 1/1/50/50;
}

.centered{
  display: flex;
  justify-content: center;
}

.vertical-centered{
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-items-centered{
  justify-content: center;
}

.column{
  flex-direction: column;
  justify-content: flex-start;
}

.city{
  position: absolute;
  z-index: 3;
  user-select: none;
  width:10%;
  height: auto;
  
}

.city:hover {
  animation-name: hovered;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  cursor: pointer;
}

 @keyframes hovered {
   0% {
    scale:100%;
   }

   100% {
    scale:120%;
   }
 }

.city:active{
  opacity: 70%;
}
  
  .leftColumn {
    width:10%;
    background-color: darkgray;
  }
  
  .rightColumn {
    width:20vw;
    height:95vh;
    background-image: url('./Photos/header.jpg');
    border-left: 4px solid black;
    border-right: 4px solid black;
  }

  .libraryContainer{
    overflow: auto;
    height: 100%;
    z-index: 1;
  }
  
  .navBar {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .navBar > div {
    flex-grow: 1;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .back-arrow {
   scale: 200%;
   align-self: flex-start;
   left: 2%;
   top: 5%;
   position: absolute;
   z-index: 10;
  }
  .back-arrow:hover{
    cursor: pointer;
  }

  .expander {
    scale: 200%;
   align-self: flex-start;
   left: 95%;
   top: 5%;
   position: absolute;
   z-index: 10;
  }

  .expander:hover{
    cursor: pointer;
  }

  .city-delete {
    scale: 400%;
    align-self: flex-start;
    left: 88%;
    top: 94%;
    position: absolute;
    z-index: 3;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: .25vh;
    padding-bottom: .25vh;
  }


  .thumbnail{
    width: 7vw;
    margin:1vw;
  }

  .flex{
    display: flex;
  }

  .library-item{
    /* background-image: linear-gradient(to bottom, #2f3034, #27282d 20%,#27282d 80%, #2f3034 100%); */
    justify-content: space-between;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    border-bottom: 2px solid black;
  }
  

  .dragging {
    opacity: 50%;
  }

  .absolute{
    position: absolute;
  }

  .add-button-size{
    height:100%;
    scale: 1.5;
    aspect-ratio: 1;
    background-color: transparent;
    border: none;
  }
  .add-button-size:hover{
    animation-name: hoveredLibraryButtons;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    cursor: pointer;
  }

  @keyframes hoveredLibraryButtons {
    0% {
     scale:1.5;
    }
 
    100% {
     scale:3;
    }
  }

  .editing {
    position: absolute;
    z-index: 3;
    user-select: none;
    animation-name: hovered;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .edit-arrow-left{
    position: relative;
    
  }
  .preview{
    max-height: 30vh;
    overflow: hidden;
  }

  .createWorld{
    z-index: 1;
  }

  .preview-window{
    border: 2px solid black;
    border-left: none;
    width:100%;
  }

  .createClickableButton{
    width:100%;
    height:4vh;
    background-image: url('./Photos/header.jpg');
    border: none;
  }

  .createIconWords:hover{
    cursor: pointer;
    animation-name: hovered;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .profileInfo{
    /* border-bottom: 4px solid black; */
    display: flex;
    /* background-color: #27282d; */
    height: 5vh;
    background-image: url('./Photos/header.jpg');
    background-size: 100vw;
    justify-content: flex-end;
    position: fixed;
    width: 100vw;
    z-index: 6;
    border-bottom: 2px solid black;
  }

  .profilePic{
    position: relative;
    height: 4vh;
    margin-right: 1vw;
  }

  .profileName{
    position: relative;
    height: 4vh;
    margin-top: .5vh;
    margin-right: 2vw;
    z-index: 3;
    cursor: pointer;
    }

  .profileHome{
    position: relative;
    height: 4vh;
    margin-left: 1vw;
    margin-top: .5vh;
    margin-right: auto;
    cursor: pointer;
  }  

  .profileHome:hover{
    cursor: pointer;
  }
  .loginForm{
    display:flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    height: 94.7vh;
    width:100%;
    }

  .loginForm > * {
    width:25vw;

  }

  .navbarIcon{
    width:80%;
  }

  .navItem{
    border-bottom: 4px solid black;
    border-top: 4px solid black;
  }

  .navItem:hover{
    cursor: pointer;
  }
.currentWorld{
  width:100%;
}  

.worldSelectAndEditMode{
  display: flex;
}

.world-select {
width:50%;

} 

.worldEdit{
  z-index: 1;
  display: flex;
  flex-direction: column;
  height:100%;
}

.mapSelectorContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
  height:100%
  
}



.mapSelectorCartHolder{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7vh;
  z-index: 4;
  overflow-wrap: normal;
}

.mapSelectorCartHolderAllMap{
  display: flex;
  width:95vw;
  flex-wrap: wrap;
  justify-content: center;
}

#recentMaps{
  font-size: 3rem;
  z-index: 1;
}

.mapSelectorHolder{
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  justify-content: center;
  align-items: end;
}

.mapSelector{
  max-height:50vh;
  background-image: linear-gradient(to bottom, #2f3034, #27282d 25%,#27282d 75%, #2f3034 100%);
  border: 3px solid black;
  border-radius: 5%;
  aspect-ratio: 9/16;
  margin: 1%;
  width: 15vw;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: end;
}

.mapSelector > img {
  height: 100%;
  position: absolute;
}
.bannerContainer{
  display:flex;
  justify-content: center;
}

.worldName{
  z-index: 1;
  margin-bottom: 11vh;
  position: absolute;
}

.banner{
  position: absolute;
  width:25vw;
  margin-left: 1rem;
}

.mapSelector:hover{
  animation-name: hoveredMapSelectors;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes hoveredMapSelectors {
  0% {
   scale:1;
  }

  100% {
   scale:1.1;
  }
}

.recentMapBackground{
  position: absolute;
  width: 95vw;
  height: 55%;
  overflow: hidden;
}




.registerButton {
  margin-top: .5vh;
  z-index: 1;
  cursor: pointer;
}

.loginLabel{
  z-index: 1;
  margin-top: 20vh;
}

.loginForm > h2,input,button {
  z-index: 1;
}

.loginForm > input{
  max-width: 400px;
}

/* .register:hover{
  cursor: pointer;
  animation-name: hovered;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
} */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./Photos/header.jpg');
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #27282d;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #444548;
}

input:focus + .slider {
  box-shadow: 0 0 1px #27282d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.editmode {
  width: 50%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.select-box {
  position: relative;
  display: block;
  width: 70%;
  margin: 0 auto;
  font-size: 18px;
  background-image: url('./Photos/header.jpg');
  border: none;
  cursor: pointer;
  
  &__current {
    position: relative;
    cursor: pointer;
    outline: none;
    border: none;
    
    &:focus {
      & + .select-box__list {
        opacity: 1;
        border:none;
        

        animation-name: none;
        
        .select-box__option {
          cursor: pointer;
          border: none;
        }
      }
      
      .select-box__icon {
        transform: translateY(-50%) rotate(180deg);
        border: none;
      }
    }
  }
  
  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    transition: 0.2s ease;
    border: none;
  }
  
  &__value {
    display: flex;
    border: none;
    cursor: pointer;
  }
  
  &__input {
    display: none;
    border: none;
    cursor: pointer;
    
    &:checked + .select-box__input-text {
      display: block;
      border: none;
    }
  }
  
  &__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    border: none;
  }
  
  &__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    border: none;
    cursor: pointer;
    
    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
  
  }
  
  &__option {
    display: block;
    padding: 15px;
    background-color: #27282d;
    border: none;
    cursor: pointer;
    
    &:hover,
    &:focus {
      color: #546c84;
      background-color: #27282d;
      border: none;
      cursor: pointer;
    }
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}


.createIcon{
  z-index: 1;
}
.options{
  z-index: 1;
}

.option-item{
  padding-left: 1vw;
}

.option-item:hover{
  cursor: pointer;
}

.allMapsButton:hover{
  cursor: pointer;
}

.allMaps{
  margin-top: 5vh;
  height:95vh;
  display: flex;
  z-index: 1;
  justify-content: center;
  overflow: auto;
  position: relative;
}

.allMapBackground{
  position: absolute;
  width: 100%;
  height: 200%;
  overflow: hidden;
  margin-top: 10vh;
}

.login-button{
  background: transparent;
  border: none;
  cursor: pointer;
}

/* .login-button:hover{
  cursor: pointer;
    animation-name: hovered;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
} */

.login{
  z-index: 1;
  height: 95vh;
  margin-top: 5vh;
  
}

.upload-button{
  margin-top: 2vh;
  border: none;
  margin-bottom: 2vh;
  width:100%;
  background-image: url('./Photos/header.jpg');
  cursor: pointer;
}

.newCity{
  width:15%
}

.createClickable_container{
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
}

.loading{
  position: absolute;
  z-index: 5;
  left:50%;
}

 /* .fadeingBetweenMaps{
  width: 100%;
  height:100%;
  background-color: black;
  position:absolute;
  opacity: 0%;
} */

/* .fade{
  animation-name: increeseOpacityFade;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 9;
} */

/* @keyframes increeseOpacityFade {
  0% {
   opacity: 0%;
  }

  100%{
    opacity: 100%;
  }
} */

/* .fadeReverse{
  animation-name: increeseOpacityFade;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  z-index: 9;
}  */

.tutorial_box_low{
  width:50%;
  height:25%;
  position: absolute;
  z-index: 2;
  top:70%;
}

.tutorial_box_middle{
  width:50%;
  height:25%;
  position: absolute;
  z-index: 2;
  top:35%;
}

.tutorialBackground{
  position: absolute;
  z-index: 1;
  height: 35vh;
  width:100%;
}
.tutorialDirections{
  width: 60%;
}
.tutorialInstructions{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tutorialInstructions > h2{
  z-index: 1;
}

.tutorial-button{
  width: 10vw;
  background-image: url('./Photos/header.jpg');

}

.tutorialButtonHolder {
  width:120%;
  justify-content: space-between;
}

.tutorialContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.spaceBetween{
  justify-content: space-between;
}

.padding-4{
  padding: 4rem;
}

.communityMapsContainerContainer{
 max-width: 2548px;
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 100vh;
}

#root{
  display: flex;
  flex-direction: column;
  position: relative;
  height:100%;
  overflow: hidden;
} 

#root::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: rgb(95, 94, 94);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

  
.communityMapsContainer{
  width:96%;
  height:91vh;
  overflow: hidden;
  display: flex;
  margin-top: 2rem;
  margin-left: 4rem;
  margin-right: 4rem;
  justify-content: center;
  
  /* align-items: center; */
  
  
}
.communityMapHolder{
  display: flex;
  flex-direction: column;
  align-items: center;
  width:50vw;
  flex-direction: column;
  /* border: 2px solid red; */
  
 
}

.communityMap{
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  margin-top: 4rem;
  z-index: 5;
}

.communityMapFade{
  animation-name: fadeCommunityMap;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes fadeCommunityMap {
  0% {
   opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  100% {
   opacity: 100%;
  }
}

.tickerBox{
  display:flex;
  width:20%;
  margin-bottom: 1vh;
  justify-content: space-between;
  align-items: center;
}

.ticker{
  border:2px solid black;
  width:1vw;
  height:1vw;
  margin-top: 1vw;
  z-index: 1;
  background-image: url('./Photos/header.jpg');
}

.bigTicker{
  border:2px solid black;
  width:1.3vw;
  height:1.3vw;
  margin-top: 1vw;
  background-image: url('./Photos/header.jpg');
  box-shadow: 0 0 10px grey;
  z-index: 1;
}

.communityMapButton{
  background-color: transparent;
  border: none;
  margin-left: 1rem;
  margin-bottom: 1%;
  z-index: 4;
}
.communityMapButton > div{
  border: 3px solid black;
  border-radius: .5rem;
  height: 50%;
  width: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #27282d;
  background-image: url('./Photos/header.jpg');
}

.homePage{
  display: flex;
  align-items: center;
  justify-content: center;
}

.communityMapButton > div:hover{
  animation-name: hoveredfeaturedMapButton;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes hoveredfeaturedMapButton {
  0% {
   scale:1;
  }

  100% {
   scale:1.1;
  }
}

.communityMapInfo > span{ 
  display: flex;
  flex-direction: row;
  margin-left: 6vw;
  justify-content: start;
  font-size: 1.2rem;
  z-index: 1;
}

.communityMapInfo > span:last-child{
  margin-bottom: 30%;
}

.communityMapInfo > img {
  position: absolute;
  max-width: 750px;
  width:30vw;
}

.communityMapLabel {
  display: flex;
  flex-direction: row;
  z-index: 3;
  justify-content: center;
  font-size: 1.6rem;
  margin-top: 5vh;
  margin-left: 7vw;
}


.communityMapInfo {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  margin-left: 1rem;
  flex-grow: 1;
  justify-content: center;
  /* border: 4px solid black; */
  /* background-color: rgba(66, 65, 65, 0.5); */
  /* border-radius: 1rem; */
  height:90%;
}

a:link{
  color: black;
}
a:visited{
  color:black;
}
a{
  text-decoration: none;
}


.loginPic{
  position: absolute;
  max-height: 95vh;
  width:40vw;
  max-width: 1000px;
}

.message{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width:300px;
  flex-direction: column;
}

.messageImg{
  position: absolute;
}

.messageMessage{
  z-index: 11;
}

.messageButtons{
  z-index: 11;
  display: flex;
}
.messageButtons > button {
  width:150px;
}

.deleteWorldButton{
  background-image: url('./Photos/header.jpg');
  width: 100%;
}

.deleteWorldButtonContainer{
  display: flex;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1vh;
}

.editmodeContainer{
  height:100%;
  display:flex;
  flex-direction: column;
}

.website-name{
  left: 1%;
  position: absolute;
  
}